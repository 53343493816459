var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"container mx-auto my-10 md:flex"},[_c('aside',{staticClass:"top-0 p-4 border border-grey-2 rounded-xl"},[_c('form',{attrs:{"id":"form-filter"}},[_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"block mb-2 font-bold text-gray-900 dark:text-gray-400",attrs:{"for":"select-status"}},[_vm._v("Status Transaction")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectStatus),expression:"selectStatus"}],staticClass:"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full py-0.5 px-2",attrs:{"id":"select-status"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectStatus=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.handleStatusChange]}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Unpaid")]),_c('option',{attrs:{"value":"10"}},[_vm._v("On Process")]),_c('option',{attrs:{"value":"11"}},[_vm._v("Finished")]),_c('option',{attrs:{"value":"4"}},[_vm._v("Canceled")])])]),_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"block mb-2 font-bold"},[_vm._v("Select Trip")]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between",class:{
                'bg-primary text-white': _vm.$route.params.trip == 'safari',
                'text-gray-500': _vm.$route.params.trip != 'safari',
              },on:{"click":function($event){return _vm.handleRedirect('safari')}}},[_c('div',{staticClass:"grid grid-cols-1 content-center"},[_vm._v("Seadoo Safari")]),_c('div',{staticClass:"grid grid-cols-1 content-center"},[(_vm.$route.params.trip == 'safari')?_c('font-awesome-icon',{staticClass:"text-yellow",attrs:{"icon":"fa-solid fa-circle-check"}}):_vm._e()],1)]),_c('div',{staticClass:"cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between",class:{
                'bg-primary text-white': _vm.$route.params.trip == 'rental',
                'text-gray-500': _vm.$route.params.trip != 'rental',
              },on:{"click":function($event){return _vm.handleRedirect('rental')}}},[_c('div',{staticClass:"grid grid-cols-1 content-center"},[_vm._v("Seadoo Rental")]),_c('div',{staticClass:"grid grid-cols-1 content-center"},[(_vm.$route.params.trip == 'rental')?_c('font-awesome-icon',{staticClass:"text-yellow",attrs:{"icon":"fa-solid fa-circle-check"}}):_vm._e()],1)]),_c('div',{staticClass:"cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between",class:{
                'bg-primary text-white': _vm.$route.params.trip == 'offroad',
                'text-gray-500': _vm.$route.params.trip != 'offroad',
              },on:{"click":function($event){return _vm.handleRedirect('offroad')}}},[_c('div',{staticClass:"grid grid-cols-1 content-center"},[_vm._v("Off-Road")]),_c('div',{staticClass:"grid grid-cols-1 content-center"},[(_vm.$route.params.trip == 'offroad')?_c('font-awesome-icon',{staticClass:"text-yellow",attrs:{"icon":"fa-solid fa-circle-check"}}):_vm._e()],1)]),_c('div',{staticClass:"cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between",class:{
                'bg-primary text-white': _vm.$route.params.trip == 'onroad',
                'text-gray-500': _vm.$route.params.trip != 'onroad',
              },on:{"click":function($event){return _vm.handleRedirect('onroad')}}},[_c('div',{staticClass:"grid grid-cols-1 content-center"},[_vm._v("On-Road")]),_c('div',{staticClass:"grid grid-cols-1 content-center"},[(_vm.$route.params.trip == 'onroad')?_c('font-awesome-icon',{staticClass:"text-yellow",attrs:{"icon":"fa-solid fa-circle-check"}}):_vm._e()],1)]),_c('div',{staticClass:"cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between",class:{
                'bg-primary text-white': _vm.$route.params.trip == 'sky',
                'text-gray-500': _vm.$route.params.trip != 'sky',
              },on:{"click":function($event){return _vm.handleRedirect('sky')}}},[_c('div',{staticClass:"grid grid-cols-1 content-center"},[_vm._v("Sky Adventure")]),_c('div',{staticClass:"grid grid-cols-1 content-center"},[(_vm.$route.params.trip == 'sky')?_c('font-awesome-icon',{staticClass:"text-yellow",attrs:{"icon":"fa-solid fa-circle-check"}}):_vm._e()],1)])])])])]),_c('div',{staticClass:"flex flex-1 flex-col p-4 border border-grey-2 rounded-xl mt-4 md:mt-0"},[_c('div',{staticClass:"py-2 px-2"},[_c('div',{staticClass:"grid grid-cols-1 gap-4 px-2"},[_c('div',{},[_c('div',{staticClass:"w-full"},[_c('span',{staticClass:"font-extrabold text-black text-lg"},[_vm._v(" My Transaction ")]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"my-1"},[(_vm.listTrx.length > 0)?_c('div',{staticClass:"sm:hidden mb-8"},[_c('VueSlickCarousel',_vm._b({on:{"afterChange":_vm.trxAfterChange}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.listTrx),function(trx,index){return _c('div',{key:index,staticClass:"flex-shrink-0",on:{"click":function($event){return _vm.selectTrx(trx)}}},[_c('CardTrxInvoice',{attrs:{"dataTrx":trx,"selected":_vm.selectedTrx}})],1)}),0)],1):_vm._e(),_c('div',{staticClass:"hidden md:flex justify-between"},[_c('div',{staticClass:"flex flex-nowrap gap-4 overflow-x-auto"},_vm._l((_vm.listTrx),function(trx,index){return _c('div',{key:index,staticClass:"flex-shrink-0",on:{"click":function($event){return _vm.selectTrx(trx)}}},[_c('CardTrxInvoice',{attrs:{"dataTrx":trx,"selected":_vm.selectedTrx}})],1)}),0)])])])]),_c('div',{staticClass:"border-2 border-grey-1 my-4"}),(
                _vm.$route.params.slug == 'unpaid' &&
                Object.keys(_vm.selectedTrx) != 0
              )?_c('TransactionUnpaid',{attrs:{"dataTrx":_vm.selectedTrx},on:{"cekStatus":_vm.handleCekStatus}}):(
                _vm.$route.params.slug == 'process' &&
                Object.keys(_vm.selectedTrx) != 0
              )?_c('TransactionProcess',{attrs:{"dataTrx":_vm.selectedTrx},on:{"cekStatus":_vm.handleCekStatus}}):(
                _vm.$route.params.slug == 'finish' &&
                Object.keys(_vm.selectedTrx) != 0
              )?_c('TransactionFinished',{attrs:{"dataTrx":_vm.selectedTrx}}):(
                _vm.$route.params.slug == 'cancel' &&
                Object.keys(_vm.selectedTrx) != 0
              )?_c('TransactionCancelled',{attrs:{"dataTrx":_vm.selectedTrx}}):_c('div',{staticClass:"text-center font-bold text-xl"},[_vm._v(" No Transaction Data Found. ")])],1)])])])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }