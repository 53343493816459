<template>
  <div class="section text-center w-full">
    <div class="flex flex-col items-center mb-6 w-full">
      <v-date-picker is-expanded v-model="date" :attributes="attrs"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    return {
      date: { start: new Date(), end: new Date(year, month, 15) },
      dates: [
        new Date(year, month, 3),
        new Date(year, month, 15),
        new Date(year, month, 25),
      ],
      attrs: [
        {
          highlight: {
            color: 'gray',
            fillMode: 'none',
          },
          dates: new Date(),
        },
      ],
    };
  },
};
</script>