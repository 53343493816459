<template>
  <div class="m-2">
    <div
      class="flex justify-center pb-1"
      :class="{
        'border-b-4 border-yellow':
          Object.keys($props.selected) != 0 &&
          $props.selected.id == $props.dataTrx.id,
      }"
    >
      <div
        class="block rounded-2xl shadow-sidebar bg-white cursor-pointer w-96"
      >
        <div
          class="font-bold py-1 px-6 border-b border-gray-300 bg-primary text-white rounded-tr-2xl rounded-tl-2xl"
        >
          {{ $props.dataTrx.namapaket }}
        </div>
        <div class="grid grid-cols-5 gap-2 px-2">
          <div class="col-span-2 py-2">
            <img
              :src="fotoPaket"
              :alt="$props.dataTrx.namapaket"
              class="object-contain rounded-2xl h-full md:max-h-40 w-full"
            />
          </div>
          <div class="col-span-3 md:text-base text-sm text-left py-2">
            <div
              class="flex flex-col justify-between align-middle divide-y-2 divide-primary content-center md:font-bold font-semibold"
            >
              <div class="px-3 truncate hover:text-clip">
                <font-awesome-icon icon="fa-solid fa-file-lines" />
                {{ $props.dataTrx.nobukti }}
              </div>
              <div class="px-3 truncate hover:text-clip">
                <font-awesome-icon
                  icon="fa-solid fa-location-dot"
                  :style="{ color: 'black' }"
                  class="mt-3"
                />
                {{ $props.dataTrx.dermaga }}
              </div>
              <div class="px-3 truncate">
                <font-awesome-icon
                  icon="fa-regular fa-clock"
                  :style="{ color: 'black' }"
                  class="mt-3"
                />
                {{ $props.dataTrx.jam_awal | reformatHour }} -
                {{ $props.dataTrx.jam_akhir | reformatHour }},
                {{ $props.dataTrx.durasi }}
              </div>
              <div class="px-3 truncate">
                <font-awesome-icon
                  icon="fa-solid fa-calendar-days"
                  :style="{ color: 'black' }"
                  class="mt-3"
                />
                {{ $props.dataTrx.tgl }}
              </div>
              <div class="px-3 truncate flex flex-row">
                <span class="grid grid-cols-1 content-center"
                  ><img :src="iconUnit" alt="" class="w-4"
                /></span>
                {{ $props.dataTrx.unit }}
              </div>
            </div>
            <div>
              <div
                class="bg-primary text-white font-bold py-0.5 px-4 border border-primary rounded-2xl text-center"
              >
                {{ totaltagihan | toCurrency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTrxInvoice",
  data() {
    return {
      iconUnit: require("@/assets/icons/jetski_icon.svg"),
    };
  },
  props: ["dataTrx", "selected"],
  computed: {
    unitText() {
      var txt = [];
      for (const key in this.$props.dataTrx.daftarpesanan) {
        if (
          Object.hasOwnProperty.call(this.$props.dataTrx.daftarpesanan, key)
        ) {
          const element = this.$props.dataTrx.daftarpesanan[key];
          txt.push(element.qtyunit + " " + element.namapaket);
        }
      }
      return txt.join(", ");
    },
    isBankTransfer() {
      if (
        this.$props.dataTrx.tipe_reff == "BCA" ||
        this.$props.dataTrx.tipe_reff == "BRI" ||
        this.$props.dataTrx.tipe_reff == "PMT" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) != "CC" &&
          this.$props.dataTrx.reff.slice(0, 3) != "C2C")
      ) {
        return true;
      }
      return false;
    },
    isCreditCard() {
      if (
        this.$props.dataTrx.tipe_reff == "CC" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) == "CC")
      ) {
        return true;
      }
      return false;
    },
    totaltagihan() {
      // if(this.$props.dataTrx.tipe_reff != null && this.$props.dataTrx.tipe_reff != '' && this.$props.dataTrx.tipe_reff == "TWPAY"){
      //   return this.$props.dataTrx.jml_dskn;
      // }else{
      //   return this.$props.dataTrx.jumlahakhir;
      // }
      if (this.isBankTransfer) {
        return this.$props.dataTrx.jumlahakhir;
      } else {
        return this.$props.dataTrx.jml_dskn;
      }
    },
    fotoPaket() {
      var txt = this.$props.dataTrx.foto;
      // if (this.$props.dataTrx.tipe == "RENTAL") {
      //   txt =
      //     this.$props.dataTrx.daftarpesanan[0]?.foto ??
      //     "https://tripwe.id/ic/jetski-android-new/upload_logo/tw_sq.png";
      // }
      return txt;
    },
  },
  methods: {},
};
</script>
