<template>
  <div>
    <Header />
    <div class="container mx-auto my-10 md:flex">
      <aside class="top-0 p-4 border border-grey-2 rounded-xl">
        <form id="form-filter">
          <div class="mb-6">
            <label
              for="select-status"
              class="block mb-2 font-bold text-gray-900 dark:text-gray-400"
              >Status Transaction</label
            >
            <select
              id="select-status"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full py-0.5 px-2"
              v-model="selectStatus"
              @change="handleStatusChange"
            >
              <option value="0">Unpaid</option>
              <option value="10">On Process</option>
              <option value="11">Finished</option>
              <option value="4">Canceled</option>
            </select>
          </div>
          <!-- <div class="mb-6">
                    <label for="select-type" class="block mb-2 font-bold text-gray-900 dark:text-gray-400">Select Type</label>
                    <select id="select-type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full py-0.5 px-2">
                        <option value="0">Watersport</option>
                        <option value="1">Land Adventure</option>
                        <option value="2">Sky Adventure</option>
                    </select>
                </div> -->
          <div class="mb-6">
            <label class="block mb-2 font-bold">Select Trip</label>
            <div class="flex flex-col gap-2">
              <div
                @click="handleRedirect('safari')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'safari',
                  'text-gray-500': $route.params.trip != 'safari',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between"
              >
                <div class="grid grid-cols-1 content-center">Seadoo Safari</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'safari'"
                  />
                </div>
              </div>
              <div
                @click="handleRedirect('rental')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'rental',
                  'text-gray-500': $route.params.trip != 'rental',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between"
              >
                <div class="grid grid-cols-1 content-center">Seadoo Rental</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'rental'"
                  />
                </div>
              </div>
              <div
                @click="handleRedirect('offroad')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'offroad',
                  'text-gray-500': $route.params.trip != 'offroad',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between"
              >
                <div class="grid grid-cols-1 content-center">Off-Road</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'offroad'"
                  />
                </div>
              </div>
              <div
                @click="handleRedirect('onroad')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'onroad',
                  'text-gray-500': $route.params.trip != 'onroad',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between"
              >
                <div class="grid grid-cols-1 content-center">On-Road</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'onroad'"
                  />
                </div>
              </div>
              <div
                @click="handleRedirect('sky')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'sky',
                  'text-gray-500': $route.params.trip != 'sky',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-between"
              >
                <div class="grid grid-cols-1 content-center">Sky Adventure</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'sky'"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </aside>
      <div
        class="flex flex-1 flex-col p-4 border border-grey-2 rounded-xl mt-4 md:mt-0"
      >
        <div class="py-2 px-2">
          <div class="grid grid-cols-1 gap-4 px-2">
            <div class="">
              <div class="w-full">
                <span class="font-extrabold text-black text-lg">
                  My Transaction
                </span>
                <div class="w-full">
                  <div class="my-1">
                    <!-- carousel -->
                    <div class="sm:hidden mb-8" v-if="listTrx.length > 0">
                      <VueSlickCarousel
                        v-bind="settings"
                        @afterChange="trxAfterChange"
                      >
                        <div
                          v-for="(trx, index) in listTrx"
                          :key="index"
                          class="flex-shrink-0"
                          @click="selectTrx(trx)"
                        >
                          <CardTrxInvoice
                            :dataTrx="trx"
                            :selected="selectedTrx"
                          />
                        </div>
                      </VueSlickCarousel>
                    </div>
                    <div class="hidden md:flex justify-between">
                      <div class="flex flex-nowrap gap-4 overflow-x-auto">
                        <div
                          v-for="(trx, index) in listTrx"
                          :key="index"
                          class="flex-shrink-0"
                          @click="selectTrx(trx)"
                        >
                          <CardTrxInvoice
                            :dataTrx="trx"
                            :selected="selectedTrx"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-2 border-grey-1 my-4"></div>
              <TransactionUnpaid
                v-if="
                  $route.params.slug == 'unpaid' &&
                  Object.keys(selectedTrx) != 0
                "
                @cekStatus="handleCekStatus"
                :dataTrx="selectedTrx"
              />
              <TransactionProcess
                v-else-if="
                  $route.params.slug == 'process' &&
                  Object.keys(selectedTrx) != 0
                "
                @cekStatus="handleCekStatus"
                :dataTrx="selectedTrx"
              />
              <TransactionFinished
                v-else-if="
                  $route.params.slug == 'finish' &&
                  Object.keys(selectedTrx) != 0
                "
                :dataTrx="selectedTrx"
              />
              <TransactionCancelled
                v-else-if="
                  $route.params.slug == 'cancel' &&
                  Object.keys(selectedTrx) != 0
                "
                :dataTrx="selectedTrx"
              />
              <div v-else class="text-center font-bold text-xl">
                No Transaction Data Found.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import TransactionUnpaid from "../components/TransactionUnpaid";
import TransactionProcess from "../components/TransactionProcess";
import TransactionFinished from "../components/TransactionFinished";
import TransactionCancelled from "../components/TransactionCancelled";
import CardTrxInvoice from "../components/CardTrxInvoice";
import DatePicker from "../components/DatePicker";
import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Invoice",
  components: {
    Header,
    Footer,
    TransactionUnpaid,
    TransactionProcess,
    TransactionFinished,
    TransactionCancelled,
    CardTrxInvoice,
    DatePicker,
    LMap,
    LTileLayer,
    LMarker,
    VueSlickCarousel,
  },
  data: () => ({
    listTrx: [],
    selectedTrx: {},
    selectStatus: 0,
    typeTrip: "UATSAFARI",
    typeBooking: "safari",
    settings: {
      arrows: true,
      dots: false,
      infinite: false,
      autoplay: false,
      swipeToSlide: true,
      slideToShow: 1,
    },
  }),
  computed: {
    calSelectTrx() {
      return this.selectedTrx;
    },
  },
  created() {
    switch (this.$route.params.slug) {
      case "unpaid":
        this.selectStatus = "0";
        break;
      case "process":
        this.selectStatus = "10";
        break;
      case "finish":
        this.selectStatus = "11";
        break;
      case "cancel":
        this.selectStatus = "4";
        break;
      default:
        this.selectStatus = "0";
        break;
    }
    switch (this.$route.params.trip) {
      case "safari":
        this.typeTrip = "UATSAFARI";
        this.typeBooking = "safari";
        break;
      case "rental":
        this.typeTrip = "UATRENTAL";
        this.typeBooking = "rental";
        break;
      case "offroad":
        this.typeTrip = "UATOFFROAD";
        this.typeBooking = "offroad";
        break;
      case "onroad":
        this.typeTrip = "UATONROAD";
        this.typeBooking = "onroad";
        break;
      case "sky":
        this.typeTrip = "UATSKY";
        this.typeBooking = "sky";
        break;
      default:
        this.typeTrip = "UATPESAN";
        this.typeBooking = "all";
        break;
    }
    this.loadTrx();
  },
  methods: {
    trxAfterChange(index) {
      var trx = this.listTrx[index];
      this.selectTrx(trx);
    },
    selectTrx(trx) {
      this.selectedTrx = trx;
    },
    cekTypeTrip(trip) {
      switch (trip) {
      case "safari":
        this.typeTrip = "UATSAFARI";
        this.typeBooking = "safari";
        break;
      case "rental":
        this.typeTrip = "UATRENTAL";
        this.typeBooking = "rental";
        break;
      case "offroad":
        this.typeTrip = "UATOFFROAD";
        this.typeBooking = "offroad";
        break;
      case "onroad":
        this.typeTrip = "UATONROAD";
        this.typeBooking = "onroad";
        break;
      case "sky":
        this.typeTrip = "UATSKY";
        this.typeBooking = "sky";
        break;
      default:
        this.typeTrip = "UATPESAN";
        this.typeBooking = "all";
        break;
      }
    },
    handleRedirect(trip) {
      this.$router.replace({
        name: "Invoice",
        params: { slug: this.$route.params.slug, trip: trip },
      });
      this.cekTypeTrip(trip);
      this.loadTrx();
    },
    handleStatusChange() {
      var sts = "";
      switch (this.selectStatus) {
        case "0":
          sts = "unpaid";
          break;
        case "10":
          sts = "process";
          break;
        case "11":
          sts = "finish";
          break;
        case "4":
          sts = "cancel";
          break;
        default:
          sts = "unpaid";
          break;
      }
      this.$router.replace({
        name: "Invoice",
        params: { slug: sts, trip: this.$route.params.trip },
      });
      this.loadTrx();
    },
    handleCekStatus(data) {
      if (data.status == "4" || data.status == "3" || data.status == "12") {
        this.selectStatus = "4";
        this.$router.replace({
          name: "Invoice",
          params: { slug: "cancel", trip: this.$route.params.trip },
        });
      } else if (data.status == "1" && data.status_cair == "0") {
        this.selectStatus = "10";
        this.$router.replace({
          name: "Invoice",
          params: { slug: "process", trip: this.$route.params.trip },
        });
      } else if (data.status == "1" && data.status_cair == "1") {
        this.selectStatus = "11";
        this.$router.replace({
          name: "Invoice",
          params: { slug: "finish", trip: this.$route.params.trip },
        });
      }
      this.loadTrx();
    },
    async loadTrx() {
      this.listTrx = [];
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/transaction/order/${this.typeBooking}`, {
          status: this.selectStatus.toString(),
        });

        this.listTrx = res.data.data.item;
        this.selectedTrx = this.listTrx[0] ?? {};
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data trips, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      return;
    },
  },
};
</script>
